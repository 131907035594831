<template>
  <div id="app">
    <router-view/>
    <!--    下载App 按钮-->
<!--    <a class="new" href="/app/index.html">-->
<!--      <div class="circle" >-->
<!--        <img src="https://img12.360buyimg.com/ddimg/jfs/t1/121975/32/31227/3674/64edcaa4Fd0b64474/1a7330e26a4a40e8.png" alt="">-->
<!--      </div>-->
<!--      <p>APP下载</p>-->
<!--    </a>-->

    <!--微信按钮-->
    <!-- <div class="btn0630">
      <a id="url" @click="weixinBtn" target="_blank">
        <img class="wechat-img" src="https://img11.360buyimg.com/jdsurvey/jfs/t1/141219/21/36679/26001/649e79f6F3ad5933e/46514c3278974e48.gif" alt="">
      </a>
    </div> -->
  </div>
</template>
<script>
export default {
  // data () {
  //   return {
  //     weixin: [
  //       {
  //         url: 'https://work.weixin.qq.com/kfid/kfc7234314adc8a4ae3'
  //       },
  //       {
  //         url: 'https://work.weixin.qq.com/kfid/kfc7234314adc8a4ae3'
  //       }
  //     ]
  //   }
  // },
  created () {
    // 将store进行存储
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  mounted () {
    // this.weixinBtn()
  },
  // methods: {
  //   weixinBtn () {
  //     // console.log(this.weixin)
  //     const suiji = Math.floor(Math.random() * this.weixin.length)
  //     // console.log(this.weixin[suiji].url);
  //     const url = this.weixin[suiji].url
  //     window.open(url)
  //   }
  // }
}

</script>
<style lang="less">
*{
  margin: 0 ;
  padding: 0;
}
body{
  background: #f4f4f5;

}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  max-width: 800px;
  margin: 0 auto;

}
a{
  text-decoration: none;
  color: #2c3e50;
}
ul{
  list-style: none;
}

.header_top{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 800px;
  z-index: 999999;
  }
  .mainBG{
    background-color: #069fff;
  }
.new{
  position: fixed;
  bottom: 90px;
  right: 28px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .circle{
    width: 60px;
    height: 60px;
    background-color: red;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* border: 5px solid #fc8cb7; */
    img{

      width: 70%;
      height: 70%;

    }
  }
  p{
    color: red;
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
  }
}
.btn0630 {
  position: fixed;
  z-index: 100;
  background-color: transparent;
  left: 20px;
  bottom: 50%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img{
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width:650px) {
  .new{
    position: fixed;
    bottom: 75px;
    right: 12px;
    z-index: 9999;
    .circle{
      width: 50px;
      height: 50px;
    }
  }
  .btn0630 >a>img {
    width: 45px;
    height: 45px;
  }

}
</style>
