<template>
  <!-- header -->
  <div class="header mainBG">
    <img @click="Home" src="../assets/logo.png" alt="">
    <!-- 右侧 -->
    <div class="header_right">
      <!-- 登录图标 -->
      <p v-if="userInfo == null" @click="Login">
        <i class="iconfont icon-denglu"></i> <span>登录</span>
      </p>
      <!-- 登录成功用户图标 -->
         <p v-else-if="userInfo !== '' && this.$router.app._route.path == '/'" @click="person" >  
           <i class=" iconfont icon-jurassic_user"></i> <span>个人</span> 
          </p>
          <!-- 收藏图标 -->
           <p @click="collect" v-else-if="userInfo !== '' && (this.$router.app._route.path == '/secondPage' || this.$router.app._route.path == '/thirdPage')"  >  
             <i class="iconfont icon-shoucang1"></i> <span>收藏</span> 
            </p>
          <!-- 菜单图标 -->
        <p v-if="pageRoad === '/'" @click="handCaidan">
          <i class="iconfont icon-gengduo"></i> <span>菜单</span>
        </p>
       <!-- 返回图标 -->
      <p @click="back" v-else>
        <i class="iconfont icon-fanhui"></i> <span>返回</span>
      </p>
     
    </div>
  </div>
</template>
<script>
import Home from '@/views/Home.vue';
import { mapState } from "vuex";
import { Toast } from 'vant';
import { postMk, postCollection } from '@/api/infoList'
export default {
   props: {
    secondPage: {
      type: String,
      default: ""
    },
     thirdPage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      pageRoad: this.$router.app._route.path,
      userInfo: "",
    }
  },

  mounted() {
    this.info()
    // console.log(this.pageRoad);
    // console.log(this.secondPage)
    // console.log(this.thirdPage);
  },
  methods: {
        info() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.userInfo = userInfo;
      // console.log('user',this.userInfo);
      this.$store.commit("userInfo", userInfo);
      // console.log(this.mkId);

    },
    Home() {
      this.$router.push('/')
    },
    Login() {
      this.$router.push('/Login')

    },
    person() {
        this.$router.push("/Person");
    },
    back() {
      this.$store.commit("changeCaidan", false);
      this.$router.go(-1)
    },
    handCaidan() {
      let caidan = this.$store.state.caidan;
      if (caidan == true) {
        this.$store.commit("changeCaidan", false);
      } else {
        this.$store.commit("changeCaidan", true);
      }
  
    },
    collect() {
      if (this.pageRoad == '/secondPage') {
        this.collectMk()
      } else if (this.pageRoad == '/thirdPage') {
        this.collectTZ()
      }
    },
        collectMk() {  //收藏模块
      let params = {
        token: this.userInfo.token,
        id: this.secondPage
      }
      postMk(params).then(res => {
        console.log(res);
        if (res.data.code == 1) {
             if (res.data.msg == '收藏成功') {
            // this.$message({
            //   message: res.data.msg,
            //   type: "success",
            //   offset: 250,
            //   duration: 800
            // })
             Toast(res.data.msg);
             } else {
            //  this.$message({
            //   message: res.data.msg,
            //    offset: 250,
            //   duration: 800
            // })
             Toast(res.data.msg);

          }
        }
     
      })
    },

    collectTZ() {
      let params = {
          token: this.userInfo.token,
          id:this.thirdPage
        }
        postCollection(params).then(res => {
            if (res.data.code == 1) {
          if (res.data.msg == '收藏成功') {
            // this.$message({
            //   message: res.data.msg,
            //   type: "success",
            //   offset: 250,
            //   duration: 800
            // })
             Toast(res.data.msg);

          } else {
            this.$message({
              message: res.data.msg,
              offset: 250,
              duration: 800
            })
          }
        }
        })
      }


  }
}


</script>
<style lang="less" scoped>
.header {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  max-width: 800px;

  img {
    width: 150px;
  }

  .header_right {
    color: #fff;
    display: flex;

    p {
      .iconfont {
        font-size: 24px;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 5px;
    }
  }


}</style>