import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: '',
    token: '',
    caidan: false,
    // imgUrl: '',
    imgUrl: 'http://122.10.9.2:20001/'

  },
  getters: {
  },
  mutations: {
    // 用户信息
    userInfo (state, step) {
      state.userInfo = step
    },
    token (state, step) {
      state.token = step
    },
    changeCaidan (state, step) {
      state.caidan = step
    }
  },
  actions: {
  },
  modules: {
  }
})
