import request from "../utils/request";



let api = '/api811'
export function getOther () {  //位置1
 return  request({
   method: 'get',
     url: '/api369/json/commam/weizhi_1.json',
  })
}
export function getMenu () {  //菜单
 return  request({
    method: 'get',
    url: api+'/json/liuhecai/menu.json',
  })
}

export function getBaseset () {  //易记域名
 return  request({
    method: 'get',
    url: api+'/json/liuhecai/baseset.json',
  })
}
export function getKaijiang () {  //开奖调用
  return  request({
    method: 'get',
    url: api+'/json/am98/gonggup2.json',
  })
}
export function getamkj () {  //开奖调用
  return  request({
    method: 'get',
    url:'/kj/index.html',
  })
}
export const getPart3 = params =>{ //人气超高好料
  return request({
    method:'get',
    url:api+'/json/comm/part3.json',
    params
  })

}
export const getPart4 = params =>{ //免费资料
  return request({
    method:'get',
    url:api+'/json/comm/part4.json',
    params
  })

}
export const getPart5 = params =>{ //免费资料
  return request({
    method:'get',
    url:api+'/json/comm/part5.json',
    params
  })

}
export const getPart6 = params =>{ //精选资料
  return request({
    method:'get',
    url:api+'/json/comm/part2.json',
    params
  })

}

export const getList = params =>{  //获取帖子列表
  return request({
    method :'get',
    url: api+'/api/index/bbslists',
    params
  })
}

export const getThirdPage = params =>{// 获取帖子页面
  return request({
    method :'get',
    url: api+'/api/index/bbscon',
    params
  })
}


export const getSearch = params =>{// 搜索页面
  return request({
    method :'get',
    url: api+'/api/index/seachindex',
    params
  })
}

export const postSearch = params =>{ // 搜索
  return request({
    method :'post',
    url: api+'/api/index/seach',
    data:params
  })
}
export const postCollection = params =>{// 收藏帖子
  return request({
    method :'post',
    url: api+'/api/user/addcollecttz',
    data:params
  })
}
export const postCancelCollection = params =>{// 取消收藏
  return request({
    method :'post',
    url: api+'/api/user/delcollecttype',
    data:params
  })
}

export const getMyCollection = params =>{// 我的收藏
  return request({
    method :'get',
    url:api+'/api/user/mycollects',
    params
  })
}
export const postMk = params =>{// 收藏模块
  return request({
    method :'post',
    url: api+'/api/user/addcollectmk',
    data:params
  })
}
export const getNotice = params =>{// 系统公告
  return request({
    method :'get',
    url: api+'/api/user/gonggao',
    params
  })
}

export const getbbscontent = params =>{  //获取固定帖子
  return request({
    method :'get',
    url:  api+'/api/index/getbbscontent',
    params
  })
}

export const getIds = params => {  //获取id
  return request({
    method: 'get',
    url: api + '/json/liuhecai/webids.json',
    params
  })
}
