<template>
  <div class="home">
    <Caidan v-show="caidan"></Caidan>
    <Header class="header_top"></Header>

    <!--    <div class="home_menu">-->
    <!--      <div v-for="(item1, index1) in regular"   >-->
    <!--        <a @click="regularMenu(item1.toPath)">-->
    <!--            <img :src= "item1.url"  alt="">-->
    <!--              <p>{{ item1.title }}</p>-->
    <!--        </a>-->
    <!--          -->
    <!--          </div>-->
    <!--        <div v-for="(item,index) in menu" :key="index">-->
    <!--          <a v-if="item.iswlink== 1" :href="item.wlink">-->
    <!--           <img :src= " $store.state.imgUrl+ item.iconimage"  alt="">-->
    <!--            <p>{{ item.title }}</p> -->
    <!--          </a>-->
    <!--           <a v-else @click="toListPage(item.nlink)">-->
    <!--             <img :src= "$store.state.imgUrl+ item.iconimage"  alt="">-->
    <!--              <p>{{ item.title }}</p>-->
    <!--            </a>-->
    <!--        </div>-->
    <!--    </div>-->
    <!-- 易记域名 -->
    <div class="baseset">
      <div>
        <p v-for="(item, index) in baseset" :key="index">
          <span class="name">官方易记域名：</span>
          <span style="color: red">{{ item.name }}</span> [UC用户点存]
        </p>
      </div>
    </div>
    <!--开奖调用-->
    <div v-html="amkj"></div>
     <table style="border-collapse: collapse; width: 100%; height: 35px;" border="1">
        <tr style="height: 35px;">
            <td style="width: 100%; height: 35px; background-color: #c2e0f4; text-align: center;font-size:19px;">
                <strong>
                    <a href="https://109.495.hk" target="_blank" rel="noopener">
                        <p style="color: #e03e2d;">内幕资料，平码三中三，已连中13期！！！准时公开</p>
                    </a>
                </strong>
            </td>
        </tr>
    </table>
    <post :tiezi1="tiezi2"></post>
    <!-- 天天好彩 -->
    <div class="tthc">
      <div class="zlTitle mainBG">{{ tthc.title }}</div>
      <van-tabs>
        <van-tab
          v-for="item in tthc.content"
          :key="item.id"
          :title="item.qishu + '期 '"
        >
          <div style="padding: 0 3px" v-html="item.content"></div>
        </van-tab>
      </van-tabs>
    </div>
    <post :tiezi1="tiezi1"></post>

    <!-- 人气超高好料 -->
    <div class="popularity">
      <div class="head"><i></i> {{ wzName }}人气超高好料</div>
      <div class="popularity_main">
        <ul>
          <li
            v-for="(item, index) in popularity"
            :key="item.id"
            @click="toListPage(item.id)"
          >
            <a href="javascript:;">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- 图片 -->
    <div class="main_img">
      <img style="width: 100%" src="../assets/image/main_page.jpg" alt="" />
    </div>
    <post :tiezi1="tiezi3"></post>

    <!-- 免费资料 -->
    <div class="freebie">
      <div class="head"><i></i> {{ wzName }}免费资料大全</div>
      <div class="freebie_main">
        <ul>
          <li
            v-for="(item, index) in freebie"
            :key="item.id"
            @click="toListPage(item.id)"
          >
            <a href="javascript:;">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- 精选资料 -->
    <div class="selected">
      <div class="head"><i></i> {{ wzName }}精选资料</div>
      <div class="selected_main">
        <ul>
          <li
            v-for="(item, index) in selected"
            :key="item.id"
            @click="toListPage(item.id)"
          >
            <a href="javascript:;">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="selected">
      <div class="head"><i></i> {{ wzName }}权威资料</div>
      <div class="selected_main">
        <ul>
          <li
            v-for="(item, index) in part2"
            :key="item.id"
            @click="toListPage(item.id)"
          >
            <a href="javascript:;">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import {
  getBaseset,
  getPart3,
  getPart4,
  getPart5,
  getPart6,
  getOther,
  getIds,
  getamkj,
  getKaijiang,
} from "@/api/infoList";
import Header from "@/components/Header.vue";
import Caidan from "@/components/Caidan.vue";
import post from "@/components/post.vue";

import { mapState } from "vuex";
export default {
  name: "home",
  components: {
    Header,
    Caidan,
    post,
  },
  data() {
    return {
      tthc: "", //引用天天好彩
      wzName: "六合彩资料", //网址名
      amkj: "", //开奖
      kaijiang: "",
      baseset: [], //易记域名
      freebie: [], //免费资料
      selected: [], //精选资料
      popularity: [], //人气超高好料
      part2: [],
      postPage: [],
      tiezi1: [],
      tiezi2: [],
      tiezi3: [],
      menu: [],
      regular: [
        {
          title: "用户中心",
          toPath: "/Person",
          url: require("../assets/image/1.png"),
        },
        {
          title: "发表新帖",
          toPath: "/user/myCollect",
          url: require("../assets/image/2.png"),
        },
        {
          title: "我的收藏",
          toPath: "/user/myCollect",
          url: require("../assets/image/3.png"),
        },
        {
          title: "搜索文章",
          toPath: "/user/searchArticle",

          url: require("../assets/image/4.png"),
        },
        {
          title: "开奖直播",
          toPath: "/",
          url: require("../assets/image/6.png"),
        },
      ],
      menuUrl: "",
      toUrl: "/secondPage?id=", //跳转地址
    };
  },
  computed: {
    ...mapState(["caidan", "isLogin", "userInfo"]),
  },
  beforeMount() {
    // this.getIdsInfo();
  },
  mounted() {
    // this.getMenuInfo()
    this.getKj();
    this.weizhi1();
    this.getBasesetInfo();
    this.getPart3Info();
    this.getPart4Info();
    this.getPart5Info();
    this.getPart6Info();

    // this.iframeSrc = "https://98kj.98tkkj3.com/kj/";
  },

  methods: {
    // 跳转页面
    toListPage(id) {
      let url = this.toUrl + id;

      console.log(id, url);

      this.$router.push(url);
    },

    // regularMenu(path) {
    //   if (path == '/') {
    //
    //   }else{
    //       if (this.userInfo) {
    //       this.$router.push(path)
    //     } else {
    //       this.$router.push('/Login')
    //     }
    //   }
    //
    // },
    // 天天好彩
    weizhi1() {
      getOther().then((res) => {
        this.tthc = res.data;
      });
    },
    // getMenuInfo() {
    //   getMenu().then(res => {
    //     this.menu = res.data
    //     console.log(this.menu);
    //   })
    //  },
    getKj() {
      getamkj().then(res=>{
        this.amkj=res.data
      })
    },
    getBasesetInfo() {
      //易记域名
      getBaseset().then((res) => {
        // console.log(res,'易记域名');
        this.baseset = res.data;
        // console.log(this.baseset);
      });
    },
    getPart3Info() {
      //人气超高好料
      getPart3().then((res) => {
        this.popularity = res.data;
      });
    },
    getPart4Info() {
      //免费资料
      getPart4().then((res) => {
        this.freebie = res.data;
      });
    },
    getPart5Info() {
      //精选资料
      getPart5().then((res) => {
        // console.log(res);
        this.selected = res.data;
      });
    },
    getPart6Info() {
      getPart6().then((res) => {
        // console.log(res, "part2");
        this.part2 = res.data;
      });
    },
    getIdsInfo() {
      // getIds().then(res => {
      //   console.log(res);
      //   this.postPage = res.data.ids
      //   this.tiezi1 = this.postPage[0]
      //   this.tiezi2 = this.postPage[1]
      //   this.tiezi3 = this.postPage[2]
      // })
    },
  },
};
</script>
<style lang="less" scoped>
.home_menu {
  margin-top: 55px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  div {
    width: 20%;

    padding: 10px 0;
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
}
@media screen and (max-width: 650px) {
  .home_menu {
    p {
      font-size: 13px;
    }
  }
}
.baseset {
  margin-top: 55px;

  background-color: #fff;
  border: 1px solid #eee;
  padding: 10px 0;
  display: flex;
  justify-content: center;

  div {
    .name {
      font-weight: bold;
      color: #2c3e50;
    }
    p {
      height: 30px;
    }
  }
}

.popularity,
.freebie,
.selected {
  margin-top: 10px;

  .head {
    background-color: #fff;
    font-weight: bold;
    height: 45px;
    line-height: 45px;
    padding: 0 10px;
  }

  .popularity_main,
  .freebie_main,
  .selected_main {
    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      width: 30%;
      width: calc(33.33% - 4px);
      margin: 5px 2px;
      height: 35px;
      line-height: 35px;
      font-size: 15px;

      a {
        padding: 0;
        text-align: center;
        border: 1px solid #cfcfcf;
        border-radius: 3px;
        background: #fff;
        display: block;
      }
    }
  }
}

.main_img {
  border: 3px solid #069fff;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0 10px 0;
}

.kj {
  height: 165px;
  width: 100%;
}

.tthc {
  margin-top: 10px;

  .zlTitle {
    color: #fff;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
  }

  /deep/.van-tab {
    height: 30px;
    border: 1px solid #069fff;
    border-radius: 40px;
    margin-right: 15px;
    padding: 0 15px;
    line-height: 32px;
  }

  /deep/.van-tabs__nav--line {
    display: flex;
    align-items: center;
  }

  /deep/.van-tab--active {
    border-radius: 40px;
    background-color: #069fff;
    color: #fff;
  }
  /deep/.van-tabs__line {
    background-color: transparent !important;
  }
}
</style>
