import axios from "axios";
// import base from '@/utils/baseUrl'
const request = axios.create({
  // 每个接口相同的前缀
  // baseURL: 'http://rap.unovo.com.cn:8888/app/mock/172',
  baseURL: '/',
  timeout: 8000,

})

// 请求拦截
request.interceptors.request.use(
  (config) => {
// 请求数据+请求头   token
    const token = JSON.parse(localStorage.getItem("token") )
    config.headers.token = token
   
    return config},
   (error) => {return Promise.reject(error)
  }
)
// 请求响应
request.interceptors.response.use(
  (response) => {
  	return response},
  	(error) => {return Promise.reject(error)
  }
)

export default request 


