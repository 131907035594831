<template>
    <!-- 指定帖子页面 -->
    <div class=" postPage">
        <!-- part1 -->
            <div v-if="tablePage">
                <div v-for="(item,index) in tablePage" :key="index">
                    <div class="title mainBG">{{ item.title }}</div>
                    <div class="inner" v-html="item.content_html"></div>
                </div>
            </div>
    </div>
</template>

<script>
import { getbbscontent} from "@/api/infoList";
export default {
    props: {
        tiezi1: {
            type: Array,
            default: ""
        },

    },
    data() {
        return {
            part1: '',  
            tablePage: [],
        }
    },
    created() {
        this.stopWatch = this.$watch('tiezi1',(newCount) => {
            this.part1 = newCount

            this.info()

        })
    },
    methods: {
          stopWatching() {
            this.stopWatch()
        },
        info() {
            for (let i = 0; i < this.part1.length; i++){
                   getbbscontent({id: this.part1[i]}).then(res => {
                       this.tablePage.push(res.data.data) 
                })
            }
            console.log(this.tablePage);
        },
    }
}


</script>
<style lang="less" scoped>
    .postPage{
        .title{
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #fff;
            font-size: 20px;
        }
        .inner{
            padding: 0 3px;
        }
 
        
    }

</style>