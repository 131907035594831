<template>
	<div class="page">

		<div @click="closeCaidan" class="options_masker">
			<div  class="nav-list" >
				<div class="clearfix1" @click.stop="closeCaidan" >
					<div v-for="(item, index) in caidan" :key="index"  @click.stop="daohangItem(item.url,item.title)">
						<img :src=item.img alt="">
						<p>{{ item.title }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>


export default {
	name: 'Caidan',
	data() {
		return {
			title:"",
			caidan: [
				{
					id: 0,
					title: "首页",
					img: require("../assets/image/caidan/1.png"),
					url:'/'
				},
				{
					id: 1,
					title: "APP下载",
					img: require("../assets/image/caidan/2.png"),
					url: '/'
				},
				
				{
					id: 2,
					title: "开奖记录",
					img: require("../assets/image/caidan/1.png"),
					url:'/Kaijiang'
				},
	
				{
					id: 3,
					title: "搅珠日期",
					img: require("../assets/image/caidan/6.png"),
					url: '/jiaozhu'
				},
				{
					id:4,
					title: "挑码助手",
					img: require("../assets/image/caidan/7.png"),
					url: '/'
				},
				{
					id: 5,
					title: "投票助手",
					img: require("../assets/image/caidan/8.png"),
					url: '/votingAssistant'
				},
				{
					id: 6,
					title: "复式计算",
					img: require("../assets/image/caidan/9.png"),
					url: '/'
				},
				{
					id: 7,
					title: "聊天室",
					img: require("../assets/image/caidan/10.png"),
					url:'/'
				},
				
			],
			toUrl:'/menu'
		};
	},

	mounted() {

	},

	methods: {
	
		daohangItem(url,title) {
			// this.$store.commit('changeTitle', m)
			console.log(url, title);
		
			if (url === '/') {
				this.closeCaidan()
			} else {
				 	this.$router.push(this.toUrl + url)
				 }
			
	
		},
		back() {
			this.$router.push('/')
		},
		// 改变图标
		// changeIconCD(){
		

		// 	this.$store.commit('changeTitle', this.title)
		// },

		closeCaidan() {
			console.log('关闭')
			this.$store.commit("changeCaidan",false)


		},
		closeCaidan1() {
			console.log('点击遮罩层')
			// this.$store.commit("changeCaidan",false)


		}
	},
};
</script>

<style lang="less" scoped>
/* 遮罩层 */
.options_masker {
	position: fixed;
	top: -5px;
	left: 0;
	right: 0;
	margin: 0 auto;
	margin-top: 60px;
	max-width: 800px;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	z-index: 99999;
	display: flex;
	justify-content: flex-end;

}
@media screen and (max-width:450px) {
	.options_masker{
		margin-top: 55px;
	}
}

.nav-list {
	height: 100%;
	width: 200px;
	background: rgba(255, 255, 255, 0.9);
	border-right: 1px solid #eeeeee;
}

.clearfix1 {
	display: flex;
	// justify-content: center;
	flex-wrap: wrap;
}

.clearfix1 div {
	padding: 10px 0;
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.clearfix1 li {
	width: 50%;
	text-align: center;
	padding: 10px 0;
}

.clearfix1 img {
	width: 40px;
	height: 40px;
}

.clearfix1 p {
	font-size: 14px;
	color: #333;
	margin-top: 6px;
}

.iconfont {
	font-size: 30px;
}</style>