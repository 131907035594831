import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/secondPage',
    name: 'secondPage',
    component: () => import('../views/secondPage.vue')
  },
  {
    path: '/thirdPage',
    name: 'thirdPage',
    component: () => import('../views/thirdPage.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/Person',
    name: 'Person',
    component: () => import('../views/user/Person.vue')
  },
  {
    path: '/user/chatRoom',
    name: 'chatRoom',
    component: () => import('../views/user/chatRoom.vue')
  },
  {
    path: '/user/systemNotice',
    name: 'systemNotice',
    component: () => import('../views/user/systemNotice.vue')
  },
  {
    path: '/user/searchArticle',
    name: 'searchArticle',
    component: () => import('../views/user/searchArticle.vue')
  },
  {
    path: '/user/myComment',
    name: 'myComment',
    component: () => import('../views/user/myComment.vue')
  },
  {
    path: '/user/myCollect',
    name: 'myCollect',
    component: () => import('../views/user/myCollect.vue')
  },
  {
    path: '/menu/Kaijiang',
    name: 'Kaijiang',
    component: () => import('../views/menu/Kaijiang.vue')
  },
  {
    path: '/menu/jiaozhu',
    name: 'jiaozhu',
    component: () => import('../views/menu/jiaozhu.vue')
  },

  {
    path: '/menu/tiaoma',
    name: 'tiaoma',
    component: () => import('../views/menu/tiaoma.vue')
  },
  {
    path: '/menu/votingAssistant',
    name: 'votingAssistant',
    component: () => import('../views/menu/votingAssistant.vue')
  },
  {
    path: '/menu/jisuansx',
    name: 'jisuansx',
    component: () => import('../views/menu/jisuansx.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
