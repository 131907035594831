import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/iconfont/iconfont.css'
// Vue.use(ElementUI);
import {
  Button, Input, Form, Message, Pagination, Calendar,

  FormItem
} from 'element-ui'
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.prototype.axios = axios
Vue.prototype.$bus = new Vue()

const eventBus = new Vue({})
export default eventBus

Vue.use(Vant)

Vue.config.productionTip = false
Vue.use(Button)
Vue.use(Input)
Vue.use(Form)
Vue.use(Calendar)

// Vue.use(Message)
Vue.use(FormItem)
Vue.use(Pagination)

Vue.prototype.$message = Message

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
